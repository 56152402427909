const anchors = {
  team: 'team',
  careers: 'careers',
  contact: 'contact',
  strategy: 'strategy',
  design: 'design',
  engineering: 'engineering',
  marketing: 'marketing',
  whatwedo: 'whatwedo',
}

const pagelinks = {
  aboutus: '/who-we-are',
  ourwork: '/our-work',
  blog: '/blog',
  careers: '/careers',
  services: '/what-we-do',
}

const innerWidth = typeof window !== 'undefined' && window.innerWidth

export { anchors, pagelinks, innerWidth }
