import React, { Component } from 'react'
import Navlinks from './Navlinks'
import ScrollIndicator from './ScrollIndicator'
import ErrorMessage from './ErrorMessage'
import styled from '@emotion/styled'
import raf from 'raf'

const StickyContainer = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  background: #000;
  height: 82px;
  transition: top 0.25s;
  @media (max-width: 1080px) {
    height: 100px;
  }
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    height: 62px;
  }

  &.hide {
    top: -82px;
    @media (max-width: 1080px) {
      top: -100px;
    }
    @media (max-width: ${props => props.theme.screenWidth.small}) {
      top: -62px;
    }
  }
`

export default class StickyNav extends Component {
  constructor(props) {
    super(props)
    this.state = {
      scrollDirection: 'down',
      lastScrollTop: 0,
      isNavOpen: false,
      barMoveUp: true,
    }
  }

  rafHandle = null

  delta = 50

  events = ['resize', 'scroll', 'pageshow', 'load']

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.state.scrollDirection === nextState.scrollDirection &&
      this.props.showNavError === nextProps.showNavError &&
      this.props.errorMessage === nextProps.errorMessage
    ) {
      return false
    }
    return true
  }

  componentDidMount() {
    this.events.forEach(e => window.addEventListener(e, this.onScroll))
  }

  componentWillUnmount() {
    if (this.rafHandle) {
      raf.cancel(this.rafHandle)
      this.rafHandle = null
    }
    this.events.forEach(e => window.removeEventListener(e, this.onScroll))
  }

  handleNavState = navState => {
    this.setState({
      isNavOpen: navState,
    })
  }

  onScroll = () => {
    const currentScrollPosition = window.pageYOffset
    const { lastScrollTop } = this.state

    // Make sure they scroll more than delta
    if (Math.abs(lastScrollTop - currentScrollPosition) <= this.delta) return

    if (currentScrollPosition <= 0 || this.state.isNavOpen) return false // Safari fix for negative scroll

    if (!this.framePending) {
      this.rafHandle = raf(() => {
        this.framePending = false

        if (lastScrollTop < currentScrollPosition) {
          this.setState({
            scrollDirection: 'up',
            lastScrollTop: currentScrollPosition,
            barMoveUp: true,
          })
        } else {
          this.setState({
            scrollDirection: 'down',
            lastScrollTop: currentScrollPosition,
            barMoveUp: false,
          })
        }
      })
      this.framePending = true
    }
  }

  render() {
    const { scrollDirection, barMoveUp } = this.state
    const {
      showScrollIndicator,
      showNavError,
      errorMessage,
      isCix,
    } = this.props
    const isScrollingUp = scrollDirection === 'up'

    return (
      <div>
        <StickyContainer className={isScrollingUp ? 'hide' : ''}>
          <Navlinks handleNavState={this.handleNavState} isCix={isCix} />
          {showNavError && (
            <ErrorMessage
              showNavError={showNavError}
              errorMessage={errorMessage}
            />
          )}
        </StickyContainer>
        {showScrollIndicator && <ScrollIndicator barMoveUp={barMoveUp} />}
      </div>
    )
  }
}
