export default (
  eventName,
  action = '',
  category = '',
  label = '',
  payload = {}
) => {
  window.dataLayer = window.dataLayer || []

  /**
   * Payload Object
   * Used to add additional parameters for GTM
   */

  window.dataLayer.push({
    event: eventName,
    action,
    category,
    label,
    ...payload,
  })
}
