import React from 'react'
import styled from '@emotion/styled'

const MessageContainer = styled('div')`
  z-index: 10;
  position: fixed;
  right: 0;
  bottom: 150px;
  min-width: 387px;
  height: 50px;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  border: solid 1px #ffffff;
  border-right: 0;
  background-color: rgba(193, 28, 92, 0.9);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 15px 5px 20px;
  visibility: hidden;
  opacity: 0;
  transition: all 1s ease;

  @media (max-width: ${props => props.theme.screenWidth.medium}) {
    height: 30px;
    min-width: 252px;
    bottom: 100px;
  }

  /* ----------------------------------------------
 * Generated by Animista on 2019-6-30 12:7:8
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  /**
 * ----------------------------------------
 * animation swing-in-right-fwd
 * ----------------------------------------
 */
  @-webkit-keyframes swing-in-right-fwd {
    0% {
      -webkit-transform: rotateY(-100deg);
      transform: rotateY(-100deg);
      -webkit-transform-origin: right;
      transform-origin: right;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateY(0);
      transform: rotateY(0);
      -webkit-transform-origin: right;
      transform-origin: right;
      opacity: 1;
    }
  }
  @keyframes swing-in-right-fwd {
    0% {
      -webkit-transform: rotateY(-100deg);
      transform: rotateY(-100deg);
      -webkit-transform-origin: right;
      transform-origin: right;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateY(0);
      transform: rotateY(0);
      -webkit-transform-origin: right;
      transform-origin: right;
      opacity: 1;
    }
  }

  &.swing-in-right-fwd {
    -webkit-animation: swing-in-right-fwd 1s
      cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
    animation: swing-in-right-fwd 1s cubic-bezier(0.175, 0.885, 0.32, 1.275)
      both;
    visibility: visible;
    opacity: 1;
  }

  /* ----------------------------------------------
 * Generated by Animista on 2019-6-30 17:53:6
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  /**
 * ----------------------------------------
 * animation swing-out-right-bck
 * ----------------------------------------
 */
  @-webkit-keyframes swing-out-right-bck {
    0% {
      -webkit-transform: rotateY(0);
      transform: rotateY(0);
      -webkit-transform-origin: right;
      transform-origin: right;
      opacity: 1;
    }
    100% {
      -webkit-transform: rotateY(-100deg);
      transform: rotateY(-100deg);
      -webkit-transform-origin: right;
      transform-origin: right;
      opacity: 0;
    }
  }
  @keyframes swing-out-right-bck {
    0% {
      -webkit-transform: rotateY(0);
      transform: rotateY(0);
      -webkit-transform-origin: right;
      transform-origin: right;
      opacity: 1;
    }
    100% {
      -webkit-transform: rotateY(-100deg);
      transform: rotateY(-100deg);
      -webkit-transform-origin: right;
      transform-origin: right;
      opacity: 0;
    }
  }

  &.swing-out-right-bck {
    -webkit-animation: swing-out-right-bck 1s
      cubic-bezier(0.6, -0.28, 0.735, 0.045) both;
    animation: swing-out-right-bck 1s cubic-bezier(0.6, -0.28, 0.735, 0.045)
      both;
  }

  strong {
    font-size: 21px;
    color: rgba(255, 255, 255, 0.9);
    line-height: 20px;
    text-transform: uppercase;
    font-family: ${props => props.theme.fonts.secondary};
    font-weight: 400;
    letter-spacing: 1.17px;
    padding-right: 20px;

    @media (max-width: ${props => props.theme.screenWidth.medium}) {
      font-size: 15px;
      letter-spacing: 1px;
    }
  }
`

const Message = styled('p')`
  font-size: 18px;
  color: #fff;
  line-height: 20px;
  padding-left: 10px;

  @media (max-width: ${props => props.theme.screenWidth.medium}) {
    font-size: 15px;
  }

  button {
    font-size: 18px;
    color: #fff;
    text-decoration: underline;
    background: none;
    border: 0;
    -webkit-appearance: none;
    appearance: none;
    font-family: ${props => props.theme.fonts.secondary};
    outline: none;

    @media (max-width: ${props => props.theme.screenWidth.medium}) {
      font-size: inherit;
    }
  }
`
const reloadWindow = () => window.location.reload()

const ReloadMessage = () => {
  return (
    <MessageContainer id="reloadMessage">
      <strong>New</strong>
      <Message>
        We’ve updated our website.{' '}
        <button onClick={reloadWindow}>Refresh page</button>
      </Message>
    </MessageContainer>
  )
}

export default ReloadMessage
