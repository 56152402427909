import React from 'react'
import styled from '@emotion/styled'
import LogoImage from '../../images/crowdlinker-logo.svg'

const Logo = styled('a')`
  display: block;
  transform: translateX(calc(7vw + 33px));

  @media (max-width: 1080px) {
    transform: translate(0);
    img {
      max-width: 199px;
    }
  }
`

const StickyLogo = () => (
  <Logo href="/" id="letsfly">
    <picture>
      <img src={LogoImage} alt="Crowdlinker" />
    </picture>
  </Logo>
)

export default StickyLogo
