/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react'
import styled from '@emotion/styled'
import Logo from './Logo'
import Hamburger from './Hamburger'
import { GradientButton } from '../Buttons'
import { anchors, pagelinks } from '../../constants'
import { Link as GatsbyLink } from 'gatsby'
import gtm from '../../util/gtmEvent'

const Wrapper = styled('div')`
  position: absolute;
  top: 0;
  z-index: 100;
  width: 100%;
`

const Container = styled('nav')`
  display: flex;
  width: 100%;
  padding: 0 76px 0 33px;
  margin: auto;
  margin-top: 22px;
  align-items: center;

  @media (max-width: 1080px) {
    width: 100%;
    padding-left: 35px;
    padding-right: 35px;
    margin-top: 44px;
  }
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    padding-left: 19px;
    padding-right: 19px;
    margin-top: 17px;
  }
`

const Link = styled('a')`
  font-family: ${props => props.theme.fonts.sourceCode};
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  margin-right: 45px;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  transition: 0.2s ease all;
  text-transform: uppercase;
  letter-spacing: 1px;

  &:hover {
    border-bottom: 1px solid #fff;
  }
`

const GatsbyNavLink = styled(Link)``.withComponent(GatsbyLink)

const MobileLink = styled(Link)`
  font-size: 18px;
  margin: 0 auto 43px;

  &:hover {
    border-bottom: 1px solid transparent;
  }
`

const GatsbyMobileNavLink = styled(MobileLink)``.withComponent(GatsbyNavLink)

const DesktopWrapper = styled('div')`
  margin-left: auto;
  display: flex;
  align-items: center;

  @media (max-width: 1300px) {
    display: none;
  }
`

const NavContainer = styled('div')``

const MobileWrapper = styled('div')`
  margin-left: auto;
  display: none;

  @media (max-width: 1300px) {
    display: block;
  }
`

const MobileMenuContainer = styled('div')`
  transition: 0.3s ease all;
  position: fixed;
  background: black;
  width: 412px;
  transition: 0.3s ease all;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  top: 0;
  right: 0;
  bottom: 0;
  transform: ${props =>
    props.menuOpen
      ? 'translateX(calc(100% - 412px))'
      : 'translateX(calc(100% + 412px))'};
  overflow: auto;
  padding-bottom: 100px;

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    width: 100%;
    transform: ${props =>
      props.menuOpen ? 'translateX(0)' : 'translateX(100%)'};
  }
`

const MobileMenuOverlay = styled('div')`
  width: 100%;
  height: 100vh;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  transition: 0.3s all;
  visibility: ${props => (props.menuOpen ? 'visible' : 'hidden')};
  background: ${props => (props.menuOpen ? 'rgba(0,0,0,0.7)' : 'transparent')};
  opacity: ${props => (props.menuOpen ? 1 : 0)};
  pointer-events: none;
`

const MobileMenu = styled('div')`
  display: flex;
  align-self: flex-start;
  flex-direction: column;
  padding-top: 160px;
`

const ctaStyle = {
  margin: 0,
  marginTop: 87,
}

class Navigation extends React.Component {
  state = {
    menuOpen: false,
  }

  handleOutsideClick = e => {
    if (this.node.contains(e.target)) {
      return
    }

    this.setState({ menuOpen: false })
    this.props.handleNavState(false)
    document.body.className = ''
  }

  componentDidMount() {
    document.body.className = ''
    document.addEventListener('mousedown', this.handleOutsideClick, false)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOutsideClick, false)
  }

  toggleMenu = e => {
    const { menuOpen } = this.state
    document.body.className = document.body.classList.contains('open')
      ? ''
      : 'open'

    this.setState({ menuOpen: !menuOpen }, () =>
      this.props.handleNavState(!menuOpen)
    )
    // Mobile - Track all Nav link clicks in GTM
    this.trackEvent()
  }

  trackEvent() {
    // Fire GTM Event to track navlinks
    gtm('Link Click', '', 'Navigation Link Click')
  }

  render() {
    const { menuOpen } = this.state
    const { isCix } = this.props
    let links = [
      {
        name: 'What We Do',
        href: `${pagelinks.services}`,
        type: 'internal',
      },
      {
        name: 'Who We Are',
        href: `${pagelinks.aboutus}`,
        type: 'internal',
      },
      {
        name: 'Our Work',
        href: `${pagelinks.ourwork}`,
        type: 'internal',
      },
      {
        name: 'Blog',
        href: `${pagelinks.blog}`,
        type: 'internal',
      },
      {
        name: 'Join Us',
        href: `${pagelinks.careers}`,
        type: 'internal',
      },
    ]
    if (isCix) {
      links = [
        {
          name: 'Case Studies',
          href: `#accordion-section`,
          type: 'anchor',
        },
        {
          name: 'CIX Panel',
          href: `#join-us-section`,
          type: 'anchor',
        },
        {
          name: 'About Our Team',
          href: `#expertise-section`,
          type: 'anchor',
        },
      ]
    }
    // @TODO remove all anchor constants and anchor links

    return (
      <Wrapper>
        <Container>
          <Logo />
          <DesktopWrapper>
            <NavContainer>
              {links.map((item, index) =>
                item.type === 'internal' ? (
                  <GatsbyNavLink
                    key={index}
                    to={item.href}
                    activeStyle={{ borderBottom: '1px solid #fff' }}
                    partiallyActive={true}
                    onClick={this.trackEvent}
                  >
                    {item.name}
                  </GatsbyNavLink>
                ) : (
                  <Link key={index} href={item.href} onClick={this.trackEvent}>
                    {item.name}
                  </Link>
                )
              )}
            </NavContainer>
            <GradientButton />
          </DesktopWrapper>
          <MobileWrapper ref={node => (this.node = node)}>
            <Hamburger toggleMenu={this.toggleMenu} menuOpen={menuOpen} />
            <MobileMenuOverlay menuOpen={menuOpen} />
            <MobileMenuContainer menuOpen={menuOpen}>
              <MobileMenu menuOpen={menuOpen}>
                {links.map((item, index) =>
                  item.type === 'internal' ? (
                    <GatsbyMobileNavLink
                      key={index}
                      to={item.href}
                      onClick={this.toggleMenu}
                    >
                      {item.name}
                    </GatsbyMobileNavLink>
                  ) : (
                    <MobileLink
                      key={index}
                      href={item.href}
                      onClick={this.toggleMenu}
                    >
                      {item.name}
                    </MobileLink>
                  )
                )}
                <GradientButton style={ctaStyle} onClick={this.toggleMenu} />
              </MobileMenu>
            </MobileMenuContainer>
          </MobileWrapper>
        </Container>
      </Wrapper>
    )
  }
}

export default Navigation
