import React from 'react'
import styled from '@emotion/styled'

const SPAN_DISTANCE = 7

const HamburgerContainer = styled('div')`
  width: 20px;
  height: 25px;
  position: absolute;
  z-index: 100;
  right: 40px;
  top: 35px;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;

  @media (max-width: 1080px) {
    top: 51px;
  }

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    right: 24px;
    top: 25px;
  }
`

const Bar = styled('span')`
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #fff;
  border-radius: 3.5px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
`

const BarOne = styled(Bar)`
  top: ${props => (props.menuOpen ? SPAN_DISTANCE : 0)}px;
  width: ${props => (props.menuOpen ? 0 : '100%')};
  left: ${props => (props.menuOpen ? '50%' : 0)};
`
const BarTwo = styled(Bar)`
  top: ${SPAN_DISTANCE}px;
  transform: ${props => (props.menuOpen ? 'rotate(45deg)' : 'initial')};
`

const BarThree = styled(Bar)`
  top: ${SPAN_DISTANCE}px;
  transform: ${props => (props.menuOpen ? 'rotate(-45deg)' : 'initial')};
`

const BarFour = styled(Bar)`
  top: ${props => (props.menuOpen ? SPAN_DISTANCE : SPAN_DISTANCE * 2)}px;
  width: ${props => (props.menuOpen ? 0 : '100%')};
  left: ${props => (props.menuOpen ? '50%' : 0)};
`

export default ({ toggleMenu, menuOpen }) => (
  <HamburgerContainer onClick={toggleMenu}>
    <BarOne menuOpen={menuOpen} />
    <BarTwo menuOpen={menuOpen} />
    <BarThree menuOpen={menuOpen} />
    <BarFour menuOpen={menuOpen} />
  </HamburgerContainer>
)
