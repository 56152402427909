import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import Footer from '../components/Footer/Footer'
import { StaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'
import { ThemeProvider } from 'emotion-theming'
import { theme } from '../styles/theme'
import OGImage from '../images/og-image.png'
import GlobalStyles from '../styles/GlobalStyles'
import ReloadMessage from './ReloadMessage'
import GetInTouchBanner from './Footer/GetInTouchBanner'

import SangBleuSunriseMediumWebMWoff2 from '../fonts/SangBleuSunrise-Medium-WebM.woff2'
import SangBleuSunriseRegularWebMWoff2 from '../fonts/SangBleuSunrise-Regular-WebM.woff2'

const Wrapper = styled('div')`
  overflow: hidden;
  position: relative;
  background-color: ${props => (props.bgColor ? props.bgColor : '#000')};
`

const Layout = props => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            siteUrl
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            {
              name: 'google-site-verification',
              content: 'P4aNJ1sePtbHdyQb1D5qy48-UtqiAU5dk9gzXVWmGWs',
            },
            { name: 'dropbox-domain-verification', content: 'tc9d98uoxrpn' },
          ]}
        >
          <meta
            name="description"
            content="Full-service digital agency providing custom solutions for the digital age. Crowdlinker builds end-to-end software & apps for growing companies & big enterprises. Experts in product strategy, human centred design & agile development."
          />
          <meta
            property="google-site-verification"
            content="P4aNJ1sePtbHdyQb1D5qy48-UtqiAU5dk9gzXVWmGWs"
          />
          <meta property="dropbox-domain-verification" content="tc9d98uoxrpn" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="website" />
          <meta
            property="og:title"
            content="Crowdlinker | Leading Digital Product & Transformation Studio"
          />
          <meta
            property="og:description"
            content="Crowdlinker helps companies build transformative user experiences, find product-market fit and scale with great strategy, design, software engineering, and product marketing."
          />
          {/* TODO Use a SEO component as described here https://www.gatsbyjs.org/docs/add-seo-component/ */}
          <meta property="og:url" content={data.site.siteMetadata.siteUrl} />
          <meta property="og:site_name" content="Crowdlinker" />
          <meta
            property="og:image"
            content={data.site.siteMetadata.siteUrl + OGImage}
          />
          <meta
            property="og:image:secure_url"
            content={data.site.siteMetadata.siteUrl + OGImage}
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:description"
            content="Crowdlinker helps companies build transformative user experiences, find product-market fit and scale with great strategy, design, software engineering, and product marketing."
          />
          <meta
            name="twitter:title"
            content="Crowdlinker | Leading Digital Product & Transformation Studio"
          />
          <meta
            name="twitter:image"
            content={data.site.siteMetadata.siteUrl + OGImage}
          />
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://use.typekit.net" />
          <link rel="preconnect" href="https://fonts.adobe.com" />
          <link rel="preconnect" href="https://code.jquery.com" />

          {/* Requests to following domains are reqrested from GTM so we 
          need them only in production */}

          {process.env.NODE_ENV == 'production' && (
            <>
              <link rel="preconnect" href="https://vars.hotjar.com" />
              <link rel="preconnect" href="https://cdn.cookielaw.org" />
              <link rel="preconnect" href="https://script.hotjar.com" />
              <link rel="preconnect" href="https://www.googletagmanager.com" />
              <link rel="preconnect" href="https://cdnjs.cloudflare.com" />
              <link rel="preconnect" href="https://www.google-analytics.com" />
              <link rel="preconnect" href="https://connect.facebook.net" />
            </>
          )}

          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css?family=Source+Code+Pro:400,500&display=swap"
          />
          <link rel="stylesheet" href="https://use.typekit.net/pcn4btl.css" />
          <html lang="en" />
        </Helmet>
        <ThemeProvider theme={theme}>
          <Wrapper {...props}>
            <GlobalStyles />
            <ReloadMessage />
            <div className="mainContainer">{props.children}</div>
            {props.footerCTA && <GetInTouchBanner isCix={props.isCix} />}
            <Footer />
          </Wrapper>
        </ThemeProvider>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
