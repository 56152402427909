export const theme = {
  colors: {
    red: '#fc544c',
    dark: '#152034',
    blue: '#4d2cd1',
    lightBlue: '#73acd8',
    brown: '#520c0c',
    magenta: '#c9286c',
    white: '#ffffff',
    black: '#000000',
  },
  fonts: {
    primaryRegular: 'SangBleuSunrise-Regular-WebM',
    primaryMedium: 'SangBleuSunrise-Medium-WebM',
    secondary: 'mr-eaves-modern',
    secondaryBook: 'mr-eaves-sans',
    sourceCode: 'Source Code Pro',
  },
  screenWidth: {
    xsmall: '320px',
    small: '640px',
    medium: '768px',
    large: '991px',
  },
}
