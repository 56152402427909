import styled from '@emotion/styled'
import React from 'react'
import { Link } from 'gatsby'

export const CTA = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 13px;
  height: 50px;
  max-width: ${props => (props.maxWidth ? props.maxWidth : null)};
  line-height: 50px;
  border: ${props => (props.blackBorder ? '1px solid #000' : '1px solid #fff')};
  border-radius: 25px;
  position: relative;
  cursor: pointer;
  transition: 0.4s all ease;
  overflow: hidden;
  text-decoration: none;
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    height: 39px;
  }

  span {
    font-family: ${props => props.theme.fonts.primaryRegular};
    font-size: 18px;
    color: ${props => (props.blackBorder ? '#000' : '#fff')};
    z-index: 1;
    transition: 0.4s all ease;
  }
  &:hover {
    span {
      color: ${props => (props.blackBorder ? '#fff' : '#000')};
    }
    &::before {
      top: -27px;
      left: -31px;
    }
  }

  &::before {
    content: '';
    width: 125%;
    height: 119px;
    border-radius: 100%;
    background-color: ${props => (props.blackBorder ? '#000' : '#fff')};
    position: absolute;
    top: 0px;
    left: -122%;
    transition: 0.4s all ease;
  }
`
const TransparentCTA = props => {
  const { blackBorder, ...rest } = props
  return (
    <CTA to="/contact" {...rest} blackBorder={blackBorder}>
      <span>{props.isCix ? 'Request a meeting' : 'Get in touch'}</span>
    </CTA>
  )
}

export default TransparentCTA
