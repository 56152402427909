import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import SocialLinks from './SocialLinks'

const FooterWrapper = styled('footer')`
  background-color: #000;
  color: #fff;
  font-weight: normal;
  padding-top: 60px;
  padding-bottom: 50px;

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    padding-top: 23px;
    padding-bottom: 26px;
  }

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
`

const FooterContainer = styled('div')`
  max-width: 1440px;
  margin: 0 auto;
  padding-left: 122px;
  padding-right: 122px;

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    padding-left: 65px;
    padding-right: 54px;
  }
`
const FooterInnerWrapper = styled('div')`
  display: flex;

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    justify-content: space-between;
  }

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    flex-direction: column;

    > div + div {
      margin-top: 50px;
    }
  }
`

const FooterRightWrapper = styled('div')`
  margin-left: auto;
  display: flex;
  flex-direction: column;

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    flex-basis: 30%;
    margin-left: initial;
  }
`

const ContactInfoContainer = styled('div')`
  flex-basis: 30%;

  @media (min-width: 992px) {
    &.no-title {
      padding-top: 5px;
    }
  }
`

const EmailAddress = styled('a')`
  display: inline-block;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 1px;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    font-size: 14px;
    line-height: 16px;
  }
`

const Address = styled('address')`
  display: flex;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 1px;
  color: #fff;
  text-transform: uppercase;
  margin: 0;
  display: block;
  font-style: normal;

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    font-size: 14px;
    line-height: 16px;
  }
`

const LocationLabel = styled('p')`
  font-weight: bold;
  margin: 0 0 25px 0;
  letter-spacing: 1px;

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    margin-bottom: 15px;
  }
`

const Phone = styled('a')`
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 1px;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    font-size: 14px;
    line-height: 16px;
  }
`

const BlogLink = styled(Link)`
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 1px;
  color: #fff;
  text-transform: uppercase;
  display: inline-block;
  text-decoration: none;

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    font-size: 14px;
    line-height: 16px;
  }
`

const FooterLink = styled(Link)`
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  display: block;
  margin: 0;
  letter-spacing: 1px;
  text-transform: uppercase;
  line-height: 20px;

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    font-size: 14px;
    line-height: 16px;
  }
`

const CopyRightsText = FooterLink.withComponent('p')

const DesktopOnly = styled('span')`
  @media (max-width: ${props => props.theme.screenWidth.large}) {
    display: none;
  }
`

const MobileOnly = styled('span')`
  @media (min-width: 992px) {
    display: none;
  }
`

const Footer = () => (
  <FooterWrapper id="footer">
    <FooterContainer>
      <FooterInnerWrapper>
        <ContactInfoContainer>
          <Address>
            <LocationLabel>North America</LocationLabel>
            <Phone href="tel:+14168462077">+1 416-846-2077</Phone>
            <br />
            119 Spadina Avenue
            <br />
            Suite 600
            <br />
            Toronto, ON M5V 2L1,{' '}
            <MobileOnly>
              <br />
            </MobileOnly>
            Canada
          </Address>
        </ContactInfoContainer>
        <ContactInfoContainer className="no-title">
          <Address>
            <DesktopOnly>
              <br />
              <br />
            </DesktopOnly>
            <Phone href="tel:+14168462077">+1 416-846-2077</Phone>
            <br />
            41 Flatbush Avenue
            <br />
            Floors 1 and 2
            <br />
            Brooklyn, NY, 11217{' '}
            <MobileOnly>
              <br />
            </MobileOnly>
            USA
          </Address>
        </ContactInfoContainer>
        <ContactInfoContainer>
          <Address>
            <LocationLabel>Europe</LocationLabel>
            <Phone href="tel:+34675236364">+34 675-236-364</Phone>
            <br />
            Carrer de la Marina, <DesktopOnly>16-18</DesktopOnly>
            <br />
            <MobileOnly>16-18</MobileOnly> Planta 27
            <br />
            08005 Barcelona,{' '}
            <MobileOnly>
              <br />
            </MobileOnly>
            Spain
          </Address>
        </ContactInfoContainer>
        <FooterRightWrapper>
          <BlogLink to="/blog">Blog</BlogLink>
          <EmailAddress href="mailto:hello@crowdlinker.com">
            hello@crowdlinker.com
          </EmailAddress>
          <CopyRightsText>© Crowdlinker inc 2021</CopyRightsText>
          <FooterLink to="/privacy">Privacy Policy</FooterLink>
          <SocialLinks />
        </FooterRightWrapper>
      </FooterInnerWrapper>
    </FooterContainer>
  </FooterWrapper>
)

export default Footer
