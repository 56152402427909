import React from 'react'
import styled from '@emotion/styled'
import warningSymbol from '../../images/warning-symbol.svg'

const ErrorContainer = styled('div')`
  display: flex;
  align-items: center;
  height: 54px;
  padding-left: 16%;
  background: rgba(193, 28, 92, 0.9);
  margin-top: ${props => (props.barMoveUp ? '0px' : '82px')};
  transition: margin-top 0.25s;

  @media (max-width: 1080px) {
    margin-top: ${props => (props.barMoveUp ? '0px' : '100px')};
  }

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    margin-top: ${props => (props.barMoveUp ? '0px' : '62px')};
    padding: 0;
    justify-content: center;
  }
  p {
    font-size: 18px;
    font-family: ${props => props.theme.fonts.secondary};
    color: white;
    margin-left: 16px;
  }
`

export default function ErrorMessage({ showNavError, errorMessage }) {
  if (!showNavError) {
    return null
  }

  return (
    <ErrorContainer>
      <img src={warningSymbol} alt={'warning'} />
      <p>{errorMessage}</p>
    </ErrorContainer>
  )
}
