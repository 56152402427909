import React from 'react'
import styled from '@emotion/styled'
import FacebookLogo from '../../images/social-icons/facebook.svg'
import InstagramLogo from '../../images/social-icons/instagram.svg'
import LinkedInLogo from '../../images/social-icons/linkedin.svg'
import TwitterLogo from '../../images/social-icons/twitter.svg'

const externalLinkProps = {
  target: '_blank',
  rel: 'noopener',
}

const socials = [
  {
    href: 'https://twitter.com/crowdlinker',
    src: TwitterLogo,
    alt: 'Twitter',
    ...externalLinkProps,
  },
  {
    href: 'https://www.linkedin.com/company/crowdlinker/',
    src: LinkedInLogo,
    alt: 'LinkedIn',
    ...externalLinkProps,
  },
  {
    href: 'https://www.facebook.com/CrowdLinker',
    src: FacebookLogo,
    alt: 'Facebook',
    ...externalLinkProps,
  },
  {
    href: 'https://www.instagram.com/crowdlinker/',
    src: InstagramLogo,
    alt: 'Instagram',
    ...externalLinkProps,
  },
]

const SocialLinksContainer = styled('div')`
  display: flex;
  align-items: flex-end;
  margin-top: auto;

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    margin-top: 18px;
  }
`

const SocialLinkAnchor = styled('a')`
  display: inline-block;
  text-decoration: none;
  margin-right: 22px;

  &:last-child {
    margin-right: 0;
  }
`

const Icon = styled('img')`
  vertical-align: bottom;
`

const SocialLink = ({ src, alt, ...rest }) => (
  <SocialLinkAnchor {...rest}>
    <Icon src={src} alt={alt} />
  </SocialLinkAnchor>
)

const SocialLinks = props => {
  return (
    <SocialLinksContainer {...props}>
      {socials.map((item, index) => (
        <SocialLink {...item} key={index} />
      ))}
    </SocialLinksContainer>
  )
}

export default SocialLinks
