import React, { Component } from 'react'
import styled from '@emotion/styled'

const BarContainer = styled('div')`
  height: 5px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 99;
  margin-top: ${props => (props.barMoveUp ? '0px' : '82px')};
  transition: margin-top 0.25s;

  @media (max-width: 1080px) {
    margin-top: ${props => (props.barMoveUp ? '0px' : '100px')};
  }

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    margin-top: ${props => (props.barMoveUp ? '0px' : '62px')};
  }
`
const Bar = styled('div')`
  height: 5px;
  background: linear-gradient(96deg, #38215d, #c8266a);
  width: ${props => props.scrolled};
`

export class ScrollIndicator extends Component {
  state = {
    scrolled: 0,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrollProgress)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollProgress)
  }

  scrollProgress = () => {
    const scrollPx = document.documentElement.scrollTop
    const winHeightPx =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight -
      document.getElementById('footer').clientHeight

    const scrolled = `${(scrollPx / winHeightPx) * 100}%`

    this.setState({
      scrolled: scrolled,
    })
  }

  render() {
    return (
      <BarContainer barMoveUp={this.props.barMoveUp}>
        <Bar scrolled={this.state.scrolled} />
      </BarContainer>
    )
  }
}

export default ScrollIndicator
