import React from 'react'
import styled from '@emotion/styled'
import TransparentCTA from '../Buttons/TransparentCTA'

const BannerContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: ${props => (props.isCix ? '438px' : '236px')};
  background-image: linear-gradient(100deg, #601947, #315567);
  background-size: 150% 100%;
  animation: Gradient 10s ease-in-out infinite;

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    margin-bottom: 0;
    background-size: 250% 100%;
    padding: 0 5%;
  }

  @keyframes Gradient {
    0% {
      background-position: 0% 0%;
    }
    50% {
      background-position: 100% 0%;
    }
    100% {
      background-position: 0% 0%;
    }
  }
`

const Title = styled('p')`
    font-size: 32px;
    margin: 19px 0 29px;
    font-family: ${props => props.theme.fonts.primaryMedium};
    font-weight: 500;
    text-align: center;
    color: #ffffff;

    @media (max-width: ${props => props.theme.screenWidth.large}) {
      font-size: 31px;
    }

    @media (max-width: ${props => props.theme.screenWidth.small}) {
      font-size: 24px;
      margin: 0 0 40px;
    }
  }
`

const CixTitle = styled('h2')`
    font-size: 50px;
    font-family: ${props => props.theme.fonts.primaryMedium};
    font-weight: 500;
    text-align: center;
    color: #ffffff;

    @media (max-width: ${props => props.theme.screenWidth.large}) {
      font-size: 31px;
    }

    @media (max-width: ${props => props.theme.screenWidth.small}) {
      font-size: 24px;
    }
  }
`

const CixText = styled('p')`
	  font-size: 38px;	
    font-weight: 300;	
    line-height: 42px;	
    text-align: center;
    color: #ffffff;
    margin: 8px 0 40px;

    @media (max-width: ${props => props.theme.screenWidth.large}) {
      font-size: 31px;
    }

    @media (max-width: ${props => props.theme.screenWidth.small}) {
      font-size: 24px;
    }
  }
`

const GetInTouchBanner = ({ isCix }) => (
  <BannerContainer isCix={isCix}>
    {isCix ? (
      <>
        <CixTitle>Ready to learn more?</CixTitle>
        <CixText>Let’s collaborate on something together.</CixText>
      </>
    ) : (
      <Title>Let’s collaborate on something together.</Title>
    )}
    <TransparentCTA isCix={isCix} />
  </BannerContainer>
)

export default GetInTouchBanner
